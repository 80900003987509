import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import HomeView from '../views/HomeView.vue'
import { ArrowRight } from '@element-plus/icons-vue'

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue')
  },
  {
    path: '/aboutus',
    name: '关于我们',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },  
  {
    path: '/contactus',
    name: '联系我们',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/ContactUsView.vue')
  },  
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/CooperationView.vue')
  },  
  {
    path: '/containerops',
    name: 'containerops',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/ContainerOps.vue')
  },
 
  {
        path: '/',
        name: "容器服务",
        // meta: { title: "技术服务", requireAuth: true},
    children: [
      {
        path: '/training',
        name: '容器基础培训',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/Training.vue'),
        meta: {title: "容器服务"},
      },
      {
        path: '/security',
        name: '容器安全服务',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/SecurityView.vue'),
        meta: {title: "容器服务"},
      },  
      {
        path: '/customize',
        name: '容器定制化服务',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/CustomizeView.vue'),
        
        meta: {title: "容器服务"},
      },  
      // {
      //   path: '/web',
      //   name: '网站定制',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/pages/WebportalsView.vue')
      // },
      {
        path: '/privatization',
        name: '容器私有化服务',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/Privatization.vue'),
        meta: {title: "容器服务"},
      }, 
    ]
},
{
    path: '/',
    name: "技术服务",
    // meta: { title: "技术服务", requireAuth: true},
  children: [
    {
      path: '/msp',
      name: '迁移服务',
      component: () => import(/* webpackChunkName: "about" */ '../views/pages/MspView.vue'),
      meta: {title: "技术服务"},
    }, 
    {
      path: '/ops',
      name: '运维服务',
      component: () => import(/* webpackChunkName: "about" */ '../views/pages/OpsService.vue'),
      meta: {title: "技术服务"},
    },
    {
      path: '/opscontainer',
      name: '容器运维服务',
      component: () => import(/* webpackChunkName: "about" */ '../views/pages/OpsContainers.vue'),
      meta: {title: "技术服务"},
    },
    {
      path: '/supports',
      name: '技术支持服务',
      component: () => import(/* webpackChunkName: "about" */ '../views/pages/Supports.vue'),
      meta: {title: "技术服务"},
    },
    // {
    //   path: '/domain',
    //   name: '域名备案',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/pages/DomainView.vue')
    //   ,
    //   meta: {title: "技术服务"},
    // },
  ]
},
{
  path: '/404',
  name: '404',
  component: () => import(/* webpackChunkName: "about" */ '../views/status/404View.vue')
},
{
  path: '/:pathMatch(.*)',
  redirect: '/404'
}
//   {
//     path: '/',
//     name: "测试",
//     // meta: { title: "测试", requireAuth: true},
//     children: [
//         {
//             path: "/test",
//             name: "测试1",
//             component: () => import('../views/TestView.vue'),
//             meta: {title: "测试1"},
//         },
//         {
//           path: '/test2',
//           name: '测试2',
//           component: () => import(/* webpackChunkName: "about" */ '../views/Test2View.vue'),
//           meta: {title: "测试2"},
//         },
//         {
//           path: '/test3',
//           name: '测试3',
//           component: () => import(/* webpackChunkName: "about" */ '../views/Test3View.vue'),
//           meta: {title: "测试3"},
//         },
//          {
//           path: '/test4',
//           name: '测试4',
//           component: () => import(/* webpackChunkName: "about" */ '../views/Test4View.vue'),
//           meta: {title: "测试4"},
//         }
//     ]
// }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// 进度条配置
NProgress.inc(100)
NProgress.configure({easing:'ease',speed: 600,showSpinner:false})

// 前置路由守卫
router.beforeEach((to,from,next) => {
  NProgress.start()

  // 设置标题头部
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "云上服务+"
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
